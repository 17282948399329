
































































import Vue from "vue";
import { Block, BlockType } from "../store/types";

import { mdiChevronRight } from "@mdi/js";

interface Data {
  blocks: Block<BlockType>[];
  mdiChevronRight: string;
}

export default Vue.extend({
  name: "BlockRenderer",
  data(): Data {
    return { blocks: [], mdiChevronRight };
  },
  methods: {
    SetBlocks(blocks: Block<BlockType>[]) {
      this.blocks = blocks;
    },
  },
});
