


























































import Vue from "vue";
export default Vue.extend({
  name: "CategoryList",
  computed: {
    categories() {
      return this.$store.getters["directorio/categories"];
    },
  },
  methods: {
    setTimeout(fn, time) {
      setTimeout(fn, time);
    },
  },
});
